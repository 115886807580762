// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---docs-features-collections-mdx": () => import("./../docs/features/collections.mdx" /* webpackChunkName: "component---docs-features-collections-mdx" */),
  "component---docs-features-invoice-payments-mdx": () => import("./../docs/features/invoice_payments.mdx" /* webpackChunkName: "component---docs-features-invoice-payments-mdx" */),
  "component---docs-features-payment-reasons-mdx": () => import("./../docs/features/payment-reasons.mdx" /* webpackChunkName: "component---docs-features-payment-reasons-mdx" */),
  "component---docs-getting-started-mdx": () => import("./../docs/getting-started.mdx" /* webpackChunkName: "component---docs-getting-started-mdx" */),
  "component---docs-partner-mdx": () => import("./../docs/partner.mdx" /* webpackChunkName: "component---docs-partner-mdx" */),
  "component---readme-md": () => import("./../README.md" /* webpackChunkName: "component---readme-md" */)
}

